import { IPropId } from '@ucheba/utils/helpers/components/prop.types'
import { IAdvBlock } from '@ucheba/store/adv/types'
import { Dispatch, SetStateAction } from 'react'
import { TModSpacing } from '@ucheba/utils/helpers/styles/mod.types'

export interface IBannerProps
  extends Required<IPropId<number>>,
    IPropOnDataExist,
    IPropExtraParams {
  spacing?: {
    bottom?: TModSpacing
    top?: TModSpacing
  } | null

  onClick?: () => void

  onClickGetStateBanner?: () => void
}

export interface IBannerVariantProps
  extends ReturnType<IUseBannerCore>,
    IPropOnDataExist {
  spacing?: {
    bottom?: TModSpacing
    top?: TModSpacing
  } | null

  onClick?: () => void
}

export interface IBannerBottomProps {
  content: {
    bg_color: string
    clientName: string
    image_url_desktop: string
    image_url_phone: string
    template: string
    url: string
  }
  pixelImg: string | null
  companyPixelUrl?: string | null | undefined
  onClick?: () => void
  onClickGetStateBanner?: () => void
}

export interface IUseBannerCore {
  (props: IPropOnDataExist & Required<IPropId<number>> & IPropExtraParams): {
    content: (IAdvBlock | null) & IBannerBottomProps
    pixelImg: string | null
    advBannerId?: number | null
    companyPixelUrl?: string | null
  }
}

export interface IPropExtraParams {
  extraParams?: {
    [index: string]: string | string[] | number | number[] | undefined
  }
}

export interface IPropOnDataExist {
  onDataExist?: () => void
}

export enum EButtonColorByTheme {
  gold = 'orange',
  navy = 'gold',
  blue = 'white',
  lime = 'emerald',
  rose = 'orange',
  plum = 'gold',
}

export interface IUseBannerBottomSheet {
  (props: {
    content: Pick<IBannerVariantProps, 'content'>
    toggleOpenBottomSheet: Dispatch<SetStateAction<boolean>>
  }): void
}

export interface IUseDynamicHeight {
  (): {
    setWrapperRef: Dispatch<SetStateAction<HTMLDivElement | null>>
    height: string
  }
}

export interface ISetCookie {
  (
    cookie: {
      name: string
      value: any
    },
    maxAge?: number
  ): void
}

export interface IBannerLidForm {
  content: IAdvBlock
  pixelImg: string | null
  companyPixelUrl: string | null | undefined
  id?: number | null
  spacing?: {
    bottom?: TModSpacing
    top?: TModSpacing
  } | null
  bannerId: string | number
}
