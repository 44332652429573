import React, { FC, memo, useState } from 'react'
import styled from 'styled-components'
import IconNotCorrect from '@ucheba/ui/icons/icon__close.svg'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { IBannerBottomProps } from '../types'

const AdvText = styled(Text)`
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  font-size: 7px;
  z-index: 10;
  cursor: help;
  transform: rotate(-90deg);
`

const ImageDesktop = styled.img`
  display: none;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;

  @media (min-width: 992px) {
    display: block;
  }
`

const ImageTable = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;

  @media (min-width: 992px) {
    display: none;
  }
`

interface IContainerProps {
  hideBanner: boolean
  bgcolor: string
}

const Container = styled.div<IContainerProps>`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  left: 0;

  transition: all 0.3s;
  transform: ${({ hideBanner }) => hideBanner && 'translateY(100%)'};
  background: ${({ bgcolor }) => bgcolor};
`

const LinkStyled = styled.a`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;

  cursor: pointer;

  @media screen and (min-width: 767px) {
    width: 750px;
  }

  @media screen and (min-width: 992px) {
    width: 970px;
  }

  @media screen and (min-width: 1200px) {
    width: 1170px;
  }
`

const Close = styled.button`
  position: absolute;
  height: 27px;
  width: 27px;
  z-index: 2;
  top: 12px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  cursor: pointer;
  border-radius: 50%;
  color: white;

  background-color: #1976d2;

  svg {
    height: 16px;
    width: 16px;
  }
`

/** Рекламный баннер нижняя растяжка которую можно скрыть  */
const BannerBottom: FC<IBannerBottomProps> = ({
  content,
  onClick,
  onClickGetStateBanner,
  pixelImg,
  companyPixelUrl,
}) => {
  const { bg_color, clientName, image_url_desktop, image_url_phone, url } = content

  const [hideBanner, setHideBanner] = useState(false)

  return (
    <Container
      bgcolor={bg_color}
      hideBanner={hideBanner}
      className={hideBanner ? '' : 'bottom-banner'}
    >
      {clientName && (
        <Tooltip
          content={<Text color='black'>{clientName}</Text>}
          position='right-center'
          width='250px'
        >
          <AdvText textAlign='center' lineHeight='xxsmall' color='white50'>
            РЕКЛАМА
          </AdvText>
        </Tooltip>
      )}

      <LinkStyled
        rel='noindex nofollow'
        href={onClick ? undefined : url}
        target='_blank'
        onClick={onClick}
      >
        <ImageDesktop src={image_url_desktop} />
        <ImageTable src={image_url_phone} />
      </LinkStyled>

      <Close
        onClick={() => {
          setHideBanner(true)
          if (onClickGetStateBanner) {
            onClickGetStateBanner()
          }
        }}
      >
        <IconNotCorrect />
      </Close>

      {pixelImg && (
        <img
          className='adv-pixel-img'
          src={pixelImg}
          style={{
            width: '1px',
            height: '1px',
            opacity: 0,
            position: 'absolute',
            top: -9999,
            right: -9999,
          }}
          alt=''
        />
      )}

      {companyPixelUrl && (
        <img
          className='adv-companyPixel-img'
          src={companyPixelUrl}
          style={{
            width: '1px',
            height: '1px',
            opacity: 0,
            position: 'absolute',
            top: -9999,
            right: -9999,
          }}
          alt=''
        />
      )}
    </Container>
  )
}

export { BannerBottom }
export default memo(BannerBottom)
