import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { advSelectors } from '@ucheba/store/adv'
import { ELoading } from '@ucheba/store/utils/response/types'
import { userDataSelectors } from '@ucheba/store/userData'
import { useRouter } from '@ucheba/utils/hooks/useRouter'
import { api } from '@ucheba/utils/helpers/api'
import { objectToQueryString } from '@ucheba/utils/helpers/router'
import {
  getCookieDomain,
  getParsedCookies,
  isBrowser,
  setCookie,
} from '@ucheba/utils/helpers/core'
import { authSelectors } from '@ucheba/store/auth'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import {
  cookieSession,
  cookieBannerBlock,
  cookieSessionCount,
  timer,
  queryBannerId,
  queryAdvBlockId,
} from './constants'
import { IUseBannerBottomSheet, IUseBannerCore } from './types'

const queueBanner = (
  id: number,
  noticeId: number | undefined,
  isForce: boolean
): boolean => {
  // если id баннеров не соответсвует беннерам нотисам, то возваращаем тру
  if ((id !== 6 && id !== 7) || isForce) return true

  const cookies = getParsedCookies()
  const block = cookies[cookieBannerBlock] // кука для блокирования появления баннера
  const session = cookies[cookieSession] // кука сессии
  const count = JSON.parse(cookies[cookieSessionCount] || '[]') // кука счетчика показов

  // если сессия еще не началась, то начинаем сессию
  if (!session && !block) {
    setCookie(cookieSession, 'start', {
      expires: dayjs().add(timer.session, 'second').toDate(),
      domain: getCookieDomain(),
      httpOnly: false,
    })
    setCookie(cookieSessionCount, JSON.stringify([noticeId]), {
      expires: dayjs().add(timer.session, 'second').toDate(),
      domain: getCookieDomain(),
      httpOnly: false,
    })
    setCookie(cookieBannerBlock, 'block', {
      expires: dayjs().add(timer.break, 'second').toDate(),
      domain: getCookieDomain(),
      httpOnly: false,
    })
    return true
  }

  if (!block && count) {
    if (count.length !== timer.counter) {
      setCookie(cookieBannerBlock, 'block', {
        expires: dayjs().add(timer.break, 'second').toDate(),
        domain: getCookieDomain(),
        httpOnly: false,
      })
      setCookie(cookieSessionCount, JSON.stringify([...count, noticeId]), {
        expires: dayjs().add(timer.session, 'second').toDate(),
        domain: getCookieDomain(),
        httpOnly: false,
      })
      return true
    }
    return false
  }

  return false
}

export const useBannerCore: IUseBannerCore = (props) => {
  const { id, onDataExist, extraParams } = props
  const bannerData = useSelector((state) => advSelectors.selectById(state, props.id))
  const bannerLoading = useSelector(advSelectors.loading)
  const userData = useSelector(userDataSelectors.entity)
  const advUserId = useSelector(advSelectors.advUserId)
  const { pathname, query } = useRouter()
  const [content, setContent] = useState(null)
  const [advBannerId, setAdvBannerId] = useState(null)
  const [pixelImg, setPixelImg] = useState(null)
  const [companyPixelUrl, setCompanyPixelUrl] = useState<string | null>(null)
  const isValid = useSelector(authSelectors.isValidToken)
  const token = useSelector(authSelectors.token) // получаем токен авторизации из стора
  const locationIdsCookies: any = JSON.parse(getParsedCookies().locationIds || 'null') // получаем locationIds из куки
  const locationIds = locationIdsCookies || userData.selectedLocations.map((el) => el.id)
  const noticesIds = JSON.parse(getParsedCookies()[cookieSessionCount] || '[]')
  const cookies = getParsedCookies()
  const block = isBrowser && cookies[cookieBannerBlock] // кука для блокирования появления баннера

  // если в куках сохранено три ИД баннера, или баннер не уведомления то не отправляем ИД
  const extraQuery = useMemo(() => {
    return (id === 7 || id === 6) && noticesIds.length !== 3
      ? { excludeBannerIds: noticesIds }
      : {}
  }, [id, noticesIds])

  // если в урле переданы баннер и рекламное место для тестирования, то используем их
  const forceBannerQuery = useMemo(() => {
    const forceBannerId = parseInt(query[queryBannerId], 10)
    const forForceBannerBlockId = parseInt(query[queryAdvBlockId], 10)
    if (forceBannerId && forForceBannerBlockId && forForceBannerBlockId === id) {
      return {
        bannerId: forceBannerId,
      }
    }
    return {}
  }, [id, query])

  const isForce = !!forceBannerQuery.bannerId

  // запрашиваем данные для баннера
  const getBanner = useCallback(() => {
    const params = {
      url: pathname,
      locationIds,
      advUserId,
      ...extraParams,
      ...extraQuery,
      ...forceBannerQuery,
    }

    // если у пользователя есть валидный токен отдаем его в в хидер запроса
    const propsForBannerFeach = isValid
      ? {
          headers: {
            'X-Auth-Token': token,
          },
        }
      : {}

    const queryString = objectToQueryString({
      ...params,
      cacheBuster: Date.now(),
    })

    // если есть блокировщик, то не делаем ненужные запросы к апи
    if (id === 6 || id === 7) {
      if (!block && noticesIds.length < timer.counter) {
        api
          .get(`adv/${id}?${queryString}`, {
            ...propsForBannerFeach,
          })
          .then((response) => {
            if (
              response?.data?.data &&
              queueBanner(Number(id), response.data?.advBannerId, isForce)
            ) {
              setContent({
                ...JSON.parse(response.data.data),
                clientName: response.data.clientName,
              })
              setPixelImg(response.data.pixelUrl)
              setCompanyPixelUrl(response.data.companyPixelUrl)
              setAdvBannerId(response.data.advBannerId)
            }
          })
      }
    } else {
      api
        .get(`adv/${id}?${queryString}`, {
          ...propsForBannerFeach,
        })
        .then((response) => {
          if (response.data?.data) {
            setPixelImg(response.data.pixelUrl)
            setCompanyPixelUrl(response.data.companyPixelUrl)
            setContent({
              ...JSON.parse(response.data.data),
              clientName: response.data.clientName,
            })
            setAdvBannerId(response.data.advBannerId)
          }
        })
    }
  }, [
    advUserId,
    id,
    isValid,
    locationIds,
    pathname,
    token,
    isForce,
    extraParams,
    block,
    extraQuery,
    forceBannerQuery,
    noticesIds.length,
  ])

  /** Закостылил запрос на получение данных
   * Из-за того, что состояние запроса у санки в сторе одинаковое, данные теряются
   * Поэтому для каждого баннера создается отдельный экземпляр запроса
   * */
  useEffect(() => {
    if (locationIds) {
      if (!isForce && (id === 6 || id === 7)) {
        // задержка в 5 сек для всплывающего баннера
        setTimeout(() => {
          getBanner()
        }, 3000)
      } else {
        getBanner()
      }
    }
  }, [])

  /** Обработка коллбека при появлении данныхъ (если коллбек есть) */
  useEffect(() => {
    if (bannerData && bannerLoading === ELoading.fulfilled && onDataExist) {
      onDataExist()
    }
  }, [bannerData, bannerLoading, onDataExist])

  return {
    content,
    pixelImg,
    advBannerId,
    companyPixelUrl,
  }
}

export const useBannerBottomSheet: IUseBannerBottomSheet = (props) => {
  const { content, toggleOpenBottomSheet } = props

  useEffect(() => {
    if (content) {
      setTimeout(() => {
        toggleOpenBottomSheet(true)
      }, 500)
    }
  }, [content, toggleOpenBottomSheet])
}
