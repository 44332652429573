import { EColor, EFontSize } from '@ucheba/utils/helpers/styles/variables'
import { IPropChildren } from '@ucheba/utils/helpers/components/prop.types'
import { EActivityInfoPart, EActivityTypes } from '@ucheba/utils/types/userActivityTypes'
import { Dispatch, SetStateAction } from 'react'

export interface ILeadFormProps extends IPropChildren, IProps {
  title: string
  list?: string[]
  text?: string
  size?: 'small' | 'large'
  withMessengers?: boolean
  color?: keyof typeof EColor
  overTitle?: string | null
  buttonText?: string | null
  imgUrl?: string | null
  titleSize?: keyof typeof EFontSize
}

interface IProps {
  institutionId: number
  activityType: EActivityTypes
  infoPart?: EActivityInfoPart | null
  leadNote?: string | null
  onSubmitForm?: () => void
  onCheckedCode?: () => void
}

export interface IUseLeadForm {
  (props: IProps): {
    getImg: (key: number) => string
    initialValues: {
      name: string
      phone: string
    }
    onSubmit: (values: any) => void
    onPhoneConfirmSubmit: (
      code: string,
      phone: string,
      setError?: Dispatch<SetStateAction<string | null>>
    ) => void
    needConfirmPhone: boolean
    onPhoneConfirmClose: () => void
    needShowMessengers: boolean
    onMessengersDialogClose: () => void
    phone: string | null
    isLoading: boolean
    error: boolean
    showInputs: boolean
  }
}

export interface IPhoneConfirmDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (
    code: string,
    phone: string,
    setError?: Dispatch<SetStateAction<string | null>>
  ) => void
  phone: string
  isLoading: boolean
  onCheckedCode?: () => void
}

export interface IMessengerDialogProps {
  messages?: Array<'vk' | 'tg' | 'wa'>
  open: boolean
  onClose: () => void
}

export enum EButtonColorByTheme {
  gold = 'orange',
  navy = 'gold',
  blue = 'white',
  lime = 'emerald',
  rose = 'orange',
  plum = 'gold',
}

export enum EInputsColor {
  navy = 'white50',
  plum = 'white50',
  blue = 'white50',
}
